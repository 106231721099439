import jwt, { Tokens } from '../auth/jwt';
import { backendAuthenticatedAxios } from '../index';

/**
 * Signs out the user
 *  by clearing JWT tokens
 */
const signOut = (): void => {
  // TODO, putting logout request as a side effect in order to not impact the user experience, can always be improved to be a blocking call
  backendAuthenticatedAxios.post<Partial<Tokens>>(
    '/account/logout',
    { refresh: jwt.refresh },
  ).finally(() => jwt.clear());
};

export default signOut;
