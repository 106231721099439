import Cookies, { CookieSetOptions } from 'universal-cookie';

const JWT_ACCESS_TOKEN_KEY = 'access';
const JWT_REFRESH_TOKEN_KEY = 'refresh';

export interface Tokens {
  access: string;
  refresh: string;
}

const cookieSetOptions: CookieSetOptions = {
  path: '/',
  sameSite: 'strict',
  secure: true,
};

/**
 * Provides controlled read/write access to JWT tokens,
 *  which are stored in cookies
 */
const jwt = {

  cookies: new Cookies('jwt'),

  get access(): string | null {
    return this.cookies.get(JWT_ACCESS_TOKEN_KEY);
  },

  get refresh(): string | null {
    return this.cookies.get(JWT_REFRESH_TOKEN_KEY);
  },

  /**
   * Removes all JWT tokens from cookies
   *
   * All subsequent requests will be anonymous
   * ---
   * ![](https://gatherer.wizards.com/Handlers/Image.ashx?multiverseid=49075&type=card)
   */
  clear(): void {
    this.cookies.remove(JWT_ACCESS_TOKEN_KEY, cookieSetOptions);
    this.cookies.remove(JWT_REFRESH_TOKEN_KEY, cookieSetOptions);
  },

  /**
   * Saves new JWT tokens to cookies
   */
  save(access?: string, refresh?: string): void {
    if (access)
      this.cookies.set(
        JWT_ACCESS_TOKEN_KEY,
        access,
        cookieSetOptions,
      );
    if (refresh)
      this.cookies.set(
        JWT_REFRESH_TOKEN_KEY,
        refresh,
        cookieSetOptions,
      );
  },
};

export default jwt;
