
import { selector } from 'recoil';

import { productTypes } from '../../constants/productType';
import { quoteTypes } from '../../constants/quoteType';
import { userRoles } from '../../constants/userRoles';
import backend from '../../lib/backend';

import { User } from './types';

/**
 * Represents user identity information
 *
 * The `get` callback is triggered only once,
 *  the first time the selector's value is read.
 *  The value is cached for instant access on subsequent reads
 *  and cannot be updated except by refreshing the cache.
 *
 * This is designed to protect features
 *  that rely on the user's identity
 *  from breaking due to data volatility.
 *
 * @see useUser to subscribe a React component to this state
 */
const userState = selector({
  key: 'userState',
  get: async () => {
    if (typeof window === 'undefined')
      return null;  // Do nothing on SSR
    else {
      return backend.fetchUser()
        .then((res) => {
          console.log('User response: ', res);

          // Chaneg needed because otherwise the anonymous user (accessed through useUser)
          // does not have the correct customer_status
          if (res.data.user_role === userRoles.anonymous) {
            const anonymousNewUser: User = {
              id: '',
              applyLink: null,
              b2bPartner: '',
              canExploreOpportunities: false,
              commonApplicationProfile: null,
              createdAt: '',
              customerId: '',
              customerStatus: '',
              dashboardVariant: '',
              dateJoined: '',
              email: '',
              employmentVerificationStatus: null,
              firstName: '',
              fptUid: '',
              fptVersion: 0,
              hasMultipleApplications: false,
              isActive: false,
              isBankAccountConnected: false,
              isFirstLogin: false,
              lastLogin: '',
              lastName: '',
              latestCustomerFundingProduct: null,
              latestCustomerRepaymentProduct: null,
              latestSponsorshipApplication: null,
              latestQuoteRequest: null,
              mobileConsent: false,
              mobileNumber: '',
              name: '',
              partnerHumanReadable: '',
              partnerId: '',
              partnerTestMode: false, // TODO: Drop field after removing partnerTestMode
              partnerUrlSlug: '',
              partnerUsers: [],
              paymentPortalUrl: '',
              photoUrl: '',
              productName: 'Income Share Loan',
              productType: productTypes.ISL,
              prospectInviteCode: '',
              quoteType: quoteTypes.custom,
              repaymentConnectionStatus: '',
              servicerAppStatus: '',
              sponsorshipApplications: [],
              updatedAt: '',
              userRole: res.data.user_role,
            };

            return anonymousNewUser;
          }

          if (res.status === 200) {
            const newUser: User = {
              id: res.data.id,
              applyLink: res.data.apply_link,
              b2bPartner: res.data.b2b_partner,
              canExploreOpportunities: res.data.can_explore_opportunities,
              commonApplicationProfile: res.data.common_application_profile,
              createdAt: res.data.created_at,
              customerId: res.data.customer_id,
              customerStatus: res.data.customer_status,
              dashboardVariant: res.data.dashboard_variant,
              dateJoined: res.data.date_joined,
              email: res.data.email,
              employmentVerificationStatus: res.data.employment_verification_status,
              firstName: res.data.first_name,
              fptUid: res.data.fpt_uid,
              fptVersion: res.data.fpt_version,
              hasMultipleApplications: res.data.has_multiple_applications,
              isActive: res.data.is_active,
              isBankAccountConnected: res.data.is_bank_account_connected,
              isFirstLogin: res.data.is_first_login,
              lastLogin: res.data.last_login,
              lastName: res.data.last_name,
              latestCustomerFundingProduct: res.data.latest_customer_funding_product,
              latestCustomerRepaymentProduct: res.data.latest_customer_repayment_product,
              latestSponsorshipApplication: res.data.latest_sponsorship_application,
              latestQuoteRequest: res.data.latest_quote_request,
              mobileConsent: res.data.mobile_consent,
              mobileNumber: res.data.mobile_number,
              name: res.data.name,
              partnerHumanReadable: res.data.partner_human_readable,
              partnerId: res.data.partner_id,
              partnerTestMode: res.data.partner_test_mode, // TODO: Drop field after removing partnerTestMode
              partnerUrlSlug: res.data.partner_url_slug,
              partnerUsers: res.data.partner_users,
              paymentPortalUrl: res.data.payment_portal_url,
              photoUrl: res.data.photo_url,
              productName: res.data.product_name,
              productType: res.data.product_type,
              prospectInviteCode: res.data.prospect_invite_code,
              quoteType: res.data.quote_type,
              repaymentConnectionStatus: res.data.repayment_connection_status,
              servicerAppStatus: res.data.servicer_app_status,
              sponsorshipApplications: res.data.sponsorship_applications,
              updatedAt: res.data.updated_at,
              userRole: res.data.user_role,
            };

            return newUser;
          } else {
            return null;  // User not found
          }
        }).catch(() => {
          throw new Error('Failed to update userState');
        });
    }
  },
});

export default userState;
