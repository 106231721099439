/**
 * Debounces an async funciton call.
 * @param func Async function to debounce
 * @param wait Amount of time in milliseconds to wait between calls
 * @returns Debounced async function
 */
export const asyncDebounce = <T extends (...args: any[]) => Promise<any>>(func: T, wait: number): (...args: Parameters<T>) => Promise<ReturnType<T>> =>  {
  let timeoutId: NodeJS.Timeout | null = null;
  let latestResolve: ((value: ReturnType<T>) => void) | null = null;
  let latestReject: ((reason?: any) => void) | null = null;

  return (...args: Parameters<T>): Promise<ReturnType<T>> => {
    // @ts-ignore TODO: Fix timeout types
    clearTimeout(timeoutId);

    return new Promise<ReturnType<T>>((resolve, reject) => {
      latestResolve = resolve;
      latestReject = reject;

      timeoutId = setTimeout(async () => {
        try {
          const result = await func(...args);
          latestResolve?.(result);
        } catch (error) {
          latestReject?.(error);
        } finally {
          timeoutId = null;
          latestResolve = null;
          latestReject = null;
        }
      }, wait);
    });
  };
};
