import palette from './palette';

const commonButton = {
  boxShadow: 'none',
  paddingTop: '14px',
  paddingBottom: '13px',
  borderRadius: 10,
};

const buttonOverrides = {
  MuiButton: {
    styleOverrides: {
      root: {
        minWidth: '156px',
        textTransform: undefined,
      },
      contained: {
        '&.disabled': {
          backgroundColor: palette.purple.bright,
          color: 'white',
        },
      },
      containedPrimary: {
        ...commonButton,
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlinedPrimary: {
        ...commonButton,
        color: palette.text.primary,
        borderColor: palette.grey.A700,
        '&:hover': {
          backgroundColor: 'white',
          borderColor: palette.grey.A700,
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        },
        '&.active': {
          backgroundColor: palette.primary.light,
          borderColor: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.light,
            borderColor: palette.primary.main,
          },
        },
      },
      containedSecondary: {
        backgroundColor: palette.primary.pastelDark,
        '&:hover': {
          backgroundColor: palette.primary.dark,
        },
      },
      containedSizeSmall: {
        padding: '10px',
        fontSize: '15px',
        lineHeight: '19.5px',
        fontWeight: 800,
      },
      label: {
        color: 'inherit',
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      root: {
        marginRight: 16,
      },
    },
  },
};

export default buttonOverrides;
