import { backendAuthenticatedAxios } from '../index';

export interface SignedUrlResponse {
    signed_url: string;
    file_path: string;
}

const getUploadSignedUrl = async (
  sponsorshipApplicationId: string,
  attachmentId: string,
  fileName: string,
): Promise<SignedUrlResponse> => {
  const response = await backendAuthenticatedAxios.post<SignedUrlResponse>(
    `/sponsorship-applications/${sponsorshipApplicationId}/generate-upload-signed-url`,
    { attachment_id: attachmentId, file_name: fileName },
  );

  return response.data;
};

export default getUploadSignedUrl;
