import palette from './palette';

const linearProgressOverrides = {
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        borderRadius: '14px',
        backgroundColor: palette.grey.A700,
        '& .MuiLinearProgress-bar': {
          backgroundColor: palette.pink.dark,
        },
      },
    },
  },
};

export default linearProgressOverrides;
