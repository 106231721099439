import { backendAuthenticatedAxios } from '../index';

interface CreateSponsorshipApplicationCreationPayload {
    url_slug: string;
}

const createSponsorshipApplication = async (payload: CreateSponsorshipApplicationCreationPayload) => {
  return await backendAuthenticatedAxios.post<void>(
    '/sponsorship-applications/create-application',
    payload,
  );
};

export default createSponsorshipApplication;
