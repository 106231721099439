import { StepsRecord } from './Steps';

export enum SponsorshipApplicationStatus {
  ACCEPTED = 'ACCEPTED',
  COMPLETE = 'COMPLETE',
  DECLINED = 'DECLINED',
  DRAFT = 'DRAFT',
  IN_REVIEW = 'IN_REVIEW',
  INCOMPLETE = 'INCOMPLETE',
  INELIGIBLE = 'INELIGIBLE',
  NEW_APPLICANT = 'NEW_APPLICANT',
  WAITLISTED = 'WAITLISTED',
  WITHDRAWN = 'WITHDRAWN',
}

export type VisibleSponsorshipApplicationStatus = Exclude<SponsorshipApplicationStatus, SponsorshipApplicationStatus.INELIGIBLE>;

export enum SponsorshipApplicationSubstatus {
  NO_SUBSTATUS = 'NO_SUBSTATUS',

  // IN_REVIEW substatuses
  INTERVIEW_PENDING = 'INTERVIEW_PENDING',
  INTERVIEW_SCHEDULED = 'INTERVIEW_SCHEDULED',
  INTERVIEW_COMPLETE = 'INTERVIEW_COMPLETE',

  // ACCEPTED substatuses
  PROFILE_INCOMPLETE = 'PROFILE_INCOMPLETE',
  PROFILE_COMPLETE = 'PROFILE_COMPLETE',
  AGREEMENT_GENERATED = 'AGREEMENT_GENERATED',
}

export type StatusColumn =
  SponsorshipApplicationStatus.ACCEPTED |
  SponsorshipApplicationStatus.COMPLETE |
  SponsorshipApplicationStatus.DECLINED |
  SponsorshipApplicationStatus.IN_REVIEW |
  SponsorshipApplicationStatus.INCOMPLETE |
  SponsorshipApplicationStatus.NEW_APPLICANT |
  SponsorshipApplicationStatus.WAITLISTED |
  SponsorshipApplicationStatus.WITHDRAWN;

export const SUBSTATUSES_DISPLAY_NAME: Record<SponsorshipApplicationSubstatus, string> = {
  [SponsorshipApplicationSubstatus.NO_SUBSTATUS]: 'N/A',

  // IN_REVIEW substatuses
  [SponsorshipApplicationSubstatus.INTERVIEW_PENDING]: 'PENDING INTERVIEW',
  [SponsorshipApplicationSubstatus.INTERVIEW_SCHEDULED]: 'INTERVIEW SCHEDULED',
  [SponsorshipApplicationSubstatus.INTERVIEW_COMPLETE]: 'INTERVIEW COMPLETED',

  // ACCEPTED substatuses
  [SponsorshipApplicationSubstatus.PROFILE_INCOMPLETE]: 'PROFILE INCOMPLETE',
  [SponsorshipApplicationSubstatus.PROFILE_COMPLETE]: 'PROFILE COMPLETE',
  [SponsorshipApplicationSubstatus.AGREEMENT_GENERATED]: 'AGREEMENT GENERATED',
};

export const SUBSTATUSES_AS_STEPS: StepsRecord = {
  [SponsorshipApplicationSubstatus.INTERVIEW_PENDING as string]: { key: 0, title: SUBSTATUSES_DISPLAY_NAME[SponsorshipApplicationSubstatus.INTERVIEW_PENDING] },
  [SponsorshipApplicationSubstatus.INTERVIEW_SCHEDULED as string]: { key: 1, title: SUBSTATUSES_DISPLAY_NAME[SponsorshipApplicationSubstatus.INTERVIEW_SCHEDULED] },
  [SponsorshipApplicationSubstatus.INTERVIEW_COMPLETE as string]: { key: 2, title: SUBSTATUSES_DISPLAY_NAME[SponsorshipApplicationSubstatus.INTERVIEW_COMPLETE] },
};

export const SPONSORSHIP_TERMINAL_STATES = [
  SponsorshipApplicationStatus.COMPLETE,
  SponsorshipApplicationStatus.DECLINED,
  SponsorshipApplicationStatus.WITHDRAWN,
];
