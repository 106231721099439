import axios from 'axios';

export type UploadProgressCallback = (progressEvent: ProgressEvent, docId: string) => void;

const pushFileToSignedUrl = async (signedUrl: string, file: File, docId: string, onUploadProgress: UploadProgressCallback): Promise<void> => {
  await axios.put(signedUrl, file, {
    onUploadProgress: (progressEvent) => onUploadProgress(progressEvent, docId),
    headers: {
      'Content-Type': 'application/octet-stream', // TODO support correct MIME type
    },
  });
};

export default pushFileToSignedUrl;
