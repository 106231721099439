import React, { FunctionComponent, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Grid } from '@mui/material';

import Form from '../../../../../lib/recoil/Form';
import usStatesAutocomplete from '../../../../../state/autocompletes/usStatesAutocomplete';
import { CommonApplicationProfile } from '../../../../../types/commonApplicationProfile';
import { UsState } from '../../../../../types/eligibility';
import { SponsorshipApplicationSubstatus } from '../../../../../types/sponsorshipApplicationStatuses';
import AppTextField from '../../../../input/AppTextField';
import Autocomplete from '../../../../input/Autocomplete';

interface MailingAddressInfoFormProps {
    commonApplicationProfile: CommonApplicationProfile;
    mailingAddressForm: Form<any>;
    fieldErrorHighlightEnabled: boolean;
    isReadOnly?: boolean;
    sponsorshipApplicationSubstatus: SponsorshipApplicationSubstatus | null;
}

const MailingAddressInfoForm: FunctionComponent<MailingAddressInfoFormProps> = ({
  commonApplicationProfile,
  fieldErrorHighlightEnabled,
  mailingAddressForm,
  isReadOnly = false,
  sponsorshipApplicationSubstatus,
}) => {
  const setState = useSetRecoilState(mailingAddressForm.fields.state.valueState);

  const profileIncomplete = sponsorshipApplicationSubstatus === SponsorshipApplicationSubstatus.PROFILE_INCOMPLETE;

  // Override isReadOnly if the profile is incomplete
  const effectiveReadOnly = isReadOnly && !profileIncomplete;

  const addressLineValue = useRecoilValue(mailingAddressForm.fields.address_line.valueState);
  const addressLineValid = useRecoilValue(mailingAddressForm.fields.address_line.validState);
  const addressDetailValid = useRecoilValue(mailingAddressForm.fields.address_detail.validState);
  const cityValue = useRecoilValue(mailingAddressForm.fields.city.valueState);
  const cityValid = useRecoilValue(mailingAddressForm.fields.city.validState);
  const stateValue = useRecoilValue(mailingAddressForm.fields.state.valueState);
  const stateValid = useRecoilValue(mailingAddressForm.fields.state.validState);
  const zipValue = useRecoilValue(mailingAddressForm.fields.zip.valueState);
  const zipValid = useRecoilValue(mailingAddressForm.fields.zip.validState);

  // Populate USState from commonApplicationProfile
  useEffect(() => {
    const usState = commonApplicationProfile?.state;

    if (usState) setState(usState);
  }, [ commonApplicationProfile, setState ]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <AppTextField
            defaultValue={commonApplicationProfile.address_line}
            disabled={effectiveReadOnly}
            error={(fieldErrorHighlightEnabled && !addressLineValid) || (profileIncomplete && addressLineValue === '')}
            field={mailingAddressForm.fields.address_line}
            id='address-line'
            label='Address'
          />
        </Grid>
        <Grid item xs={4}>
          <AppTextField
            defaultValue={commonApplicationProfile.address_detail}
            disabled={effectiveReadOnly}
            error={fieldErrorHighlightEnabled && !addressDetailValid}
            field={mailingAddressForm.fields.address_detail}
            id='address-detail'
            label='Apartment, Suite, etc.'
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <AppTextField
            defaultValue={commonApplicationProfile.city}
            disabled={effectiveReadOnly}
            error={(fieldErrorHighlightEnabled && !cityValid) || (profileIncomplete && cityValue === '')}
            field={mailingAddressForm.fields.city}
            id='city'
            label='City'
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            autocompleteState={usStatesAutocomplete}
            data-cy='state-input'
            defaultValue={commonApplicationProfile.state?.abbreviation}
            disabled={effectiveReadOnly}
            error={(fieldErrorHighlightEnabled && !stateValid) || (profileIncomplete && stateValue == null)}
            field={mailingAddressForm.fields.state}
            getOptionLabel={(option: UsState) => option.description}
            helperText={fieldErrorHighlightEnabled && !stateValid ? 'Please select a state' : ''}
            id='state-input'
            isOptionEqualToValue={(option: UsState, value: UsState) => option.abbreviation === value.abbreviation}
            label='State'
            noOptionsText='Start typing to filter states'
            variant='outlined'
          />
        </Grid>
        <Grid item xs={3}>
          <AppTextField
            defaultValue={commonApplicationProfile.zip}
            disabled={effectiveReadOnly}
            error={(fieldErrorHighlightEnabled && !zipValid) || (profileIncomplete && zipValue === '')}
            field={mailingAddressForm.fields.zip}
            id='zip'
            label='Zip'
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MailingAddressInfoForm;
