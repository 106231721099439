import {
  PaletteColor,
  PaletteColorOptions,
  PaletteOptions as MuiPaletteOptions,
  SimplePaletteColorOptions,
} from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';

interface PaletteOptions extends MuiPaletteOptions {
  pink: SimplePaletteColorOptions;
  yellow: SimplePaletteColorOptions;
  blue: PaletteColorOptions & {
    aqua: string;
    light: string;
    main: string;
  };
  green: PaletteColorOptions & {
    light: string;
  };
  purple: PaletteColorOptions & {
    bright: string;
    dark: string;
  };
  violet: ColorPartial;
  plum: PaletteColorOptions;
  primary: PaletteColor & {
    pastelLight: string;
    pastelDark: string;
  };
  warning: {
    main: string;
  };
  error: {
    main: string;
    dark: string;
  };
  grey: ColorPartial & {
    A800: string;
    dark: string;
    light: string;
    neutral: string;
  };
  text: {
    primary: string;
    secondary: string;
  };
  info: {
    main: string;
    light: string;
  };
  success: {
    main: string;
  };
  neutral: {
    plum: ColorPartial & { 1000: string | undefined };
  };
}

// Types for adding custom colors to buttons
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    plum: true;
    pink: true;
    green: true;
    purple: true;
  }
}

// Types to define custom colors for the palette
declare module '@mui/material/styles' {
  interface Palette {
    plum: Palette['primary'];
    pink: Palette['primary'];
    green: Palette['primary'];
    purple: Palette['primary'];
  }

  interface PaletteOptions {
    plum?: PaletteOptions['primary'];
    pink?: PaletteOptions['primary'];
    green?: PaletteOptions['primary'];
    purple?: PaletteOptions['primary'];
  }
}

const palette: PaletteOptions = {
  pink: {
    main: '#EFB1B1',
    light: '#F7D8D8',
    contrastText: 'rgba(246, 125, 125, 0.2)',
    dark: '#EA3DAD',
  },
  yellow: {
    main: '#FDE26D',
    light: '#FEF1B8',
  },
  blue: {
    aqua: '#B8FBFF',
    light: '#E5FEFF',
    main: '#51b3eb',
  },
  green: {
    light: '#60E13247',
  },
  /**
   * @todo Conform to standard type `SimplePaletteColorOptions` where `bright` does not exist
   */
  purple: {
    bright: '#baafff',
    dark: '#8c7aff',
  },
  /**
   * @todo
   *  Move colors `pastelLight` and `pastelDark` out of `primary` palette
   */
  primary: {
    main: '#8c7aff',
    dark: '#7F58DE',
    light: '#F4EEFF',
    pastelLight: '#D4C1FA',
    pastelDark: '#44008B',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#051E27',
  },
  plum: {
    main: '#361B47',
    light: '#1E0032',
    dark: '#4D355C',
    contrastText: '#F2FFFF',
  },
  violet: {
    200: '#F6F5FF',
    400: '#D5CFFF',
    600: '#BAAFFF',
    800: '#A395FF',
  },
  info: {
    main: '#E5FEFF',
    light: '#D6FEFF',
  },
  warning: {
    main: '#FCD31E',
  },
  error: {
    main: '#F77D7D',
    dark: '#C70039',
  },
  success: {
    main: '#2e7d32',
  },
  background: {
    default: '#FFFFFF',
  },
  text: {
    primary: '#000000',
    secondary: '#585858',
  },
  grey: {
    A400: '#BCBCBC',
    A700: '#D3D3D3',
    A800: '#F3F3F3',
    neutral: '#585858',
    light: '#D3D3D3',
    dark: '#585858',
  },
  neutral: {
    plum: {
      200: '#FAFAFB',
      300: '#EBE8EC',
      400: '#D3CDD7',
      500: '#B7ADBD',
      600: '#95869d',
      700: '#634E70',
      800: '#4D355C',
      900: '#361B47',
      1000: '#1E0032',
    },
  },
};

export default palette;
