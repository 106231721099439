import { StudentSponsorshipApplication } from '../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '../index';

import { AxiosResponse } from 'axios';

const applyToOpportunity = async (opportunityID: string):Promise<AxiosResponse<StudentSponsorshipApplication>> => {
  return backendAuthenticatedAxios.post(`/esl-student/${opportunityID}/apply-to-opportunity`);
};

export default applyToOpportunity;
