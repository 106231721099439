import { StudentSponsorshipApplication } from '../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '../';

import { AxiosResponse } from 'axios';

type StudentApplicationParams = {
    id: string;
};

const getStudentSponsorshipApplication = async ({ id }:StudentApplicationParams): Promise<AxiosResponse<StudentSponsorshipApplication>> =>
  backendAuthenticatedAxios.get<StudentSponsorshipApplication>(`/esl-student/${id}`);

export default getStudentSponsorshipApplication;
