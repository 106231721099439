import { SponsorshipApplicationOverview, StudentSponsorshipApplication } from '../../../types/sponsorshipApplication';
import { SponsorshipApplicationStatus } from '../../../types/sponsorshipApplicationStatuses';
import { SponsorshipOpportunityType } from '../../../types/sponsorshipOpportunity';
import { backendAuthenticatedAxios } from '../';

import { AxiosResponse } from 'axios';

type GetStudentSponsorshipApplicationsOptions = {
    opportunityType?: SponsorshipOpportunityType;
    status?: SponsorshipApplicationStatus[];
};

export type GroupedStudentSponsorshipApplications = {
  [SponsorshipApplicationOverview.ARCHIVED]: StudentSponsorshipApplication[];
  [SponsorshipApplicationOverview.ONGOING]: StudentSponsorshipApplication[];
  [SponsorshipApplicationOverview.REQUIRES_ACTION]: StudentSponsorshipApplication[];
};

const getStudentSponsorshipApplications = async ({ status, opportunityType }: GetStudentSponsorshipApplicationsOptions = {}): Promise<AxiosResponse<GroupedStudentSponsorshipApplications>> => {
  // Relative URL
  const relativeUrl = '/esl-student/get-sponsorship-applications';

  // Filtering options
  const filterOptions = {} as Record<string, string>;

  if (status) {
    filterOptions['status'] = status.join(',');
  }

  if (opportunityType) {
    filterOptions['opportunity_type'] = opportunityType;
  }

  // Construct the query parameters object
  const queryParamsObject: Record<string, string> = {
    ...filterOptions,
  };

  // Construct the query parameters
  const queryParams = new URLSearchParams(queryParamsObject);

  return backendAuthenticatedAxios.get<GroupedStudentSponsorshipApplications>(`${relativeUrl}?${queryParams.toString()}`);
};

export default getStudentSponsorshipApplications;
