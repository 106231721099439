import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

interface GenerateSponsorshipAgreementResponse {
  sign_url: string;
}

const getSponsorshipAgreementSignLink = async (sponsorshipApplicationId: string): Promise<AxiosResponse<GenerateSponsorshipAgreementResponse>> => {
  return backendAuthenticatedAxios.post<GenerateSponsorshipAgreementResponse>(
    `/esl-student/${sponsorshipApplicationId}/sponsorship-agreement`,
    {},
    { timeout: 120000 }, // Override timeout to be 2 minutes
  );
};

export default getSponsorshipAgreementSignLink;
