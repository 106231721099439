import { TypographyOptions } from '@mui/material/styles/createTypography';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';

import palette from './palette';

const breakpoints = createBreakpoints({});

const typography: TypographyOptions = {
  fontFamily: [
    'Roboto',
    'apercu-pro',
    'ui-sans-serif',
    'system-ui',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Helvetica Neue',
    'Arial',
    'Noto Sans',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji',
  ].join(','),
  h1: {
    fontSize: 36,
    fontWeight: 600,
    lineHeight: '46.8px',
    marginBottom: 13,
    [breakpoints.down('xs')]: {
      fontSize: 24,
      lineHeight: '31.2px',
    },
  },
  h2: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: '36.4px',
    [breakpoints.down('xs')]: {
      fontSize: 22,
      lineHeight: '28.6px',
    },
  },
  h3: {
    fontSize: 22,
    fontWeight: 600,
    lineHeight: '28.6px',
    [breakpoints.down('xs')]: {
      fontSize: 18,
      lineHeight: '23.4px',
    },
  },
  h4: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '23.4px',
    [breakpoints.down('xs')]: {
      fontSize: 16,
      lineHeight: '20.8px',
    },
  },
  h5: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '20.8px',
  },
  subtitle1: { // in Figma: `Paragraph - Large`
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    color: palette.text.secondary,
    [breakpoints.down('xs')]: {
      fontSize: 15,
      lineHeight: '22.5px',
    },
  },
  subtitle2: { // in Figma: `Paragraph - Small`
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '19.6px',
    color: palette.text.secondary,
  },
  body1: { // default <Typography> variant
    fontSize: 14,
    lineHeight: '18.2px',
    [breakpoints.down('sm')]: {
      fontSize: 15,
      lineHeight: '22.5px',
    },
  },
  body2: { // applied to <body>
    fontSize: 14,
    lineHeight: '18.2px',
  },
  button: {
    fontSize: 18,
    fontWeight: 800,
    lineHeight: '23.4px',
    textTransform: 'none',
  },
};

export default typography;
