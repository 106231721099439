import {
  StudentSponsorshipApplication,
} from '../../../types/sponsorshipApplication';
import { SponsorshipApplicationStatus, SponsorshipApplicationSubstatus } from '../../../types/sponsorshipApplicationStatuses';
import { backendAuthenticatedAxios } from '../index';

import { AxiosResponse } from 'axios';

const updateStatusStudentSponsorshipApplication = async (
  sponsorshipApplicationId: string,
  status: SponsorshipApplicationStatus | SponsorshipApplicationSubstatus,
): Promise<AxiosResponse<StudentSponsorshipApplication | null>> => {
  return backendAuthenticatedAxios.patch(
    `/esl-student/${sponsorshipApplicationId}/update-status`,
    { 'status': status },
    { timeout: 120000 }, // Override timeout to be 2 minutes
  );
};

export default updateStatusStudentSponsorshipApplication;
