import { ElementType, HTMLAttributes, ReactNode } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps, AutocompleteRenderGetTagProps, AutocompleteRenderOptionState,
  TextField,
} from '@mui/material';
import { FormHelperTextProps } from '@mui/material/FormHelperText';
import { SxProps, Theme } from '@mui/material/styles';

import { Field } from '../../lib/recoil/Form';
import ServerSideAutocomplete from '../../lib/recoil/ServerSideAutocomplete';

interface AutocompleteProps<T> extends Omit<
  MuiAutocompleteProps<T, undefined, undefined, undefined>,
  'onChange' | 'onInputChange'
> {
  field: Field<T | null>;
  autocompleteState: ServerSideAutocomplete<T>;
  label?: ReactNode;
  alternativeLabel?: ReactNode; // New prop for alternative label
  variant?: 'standard' | 'filled' | 'outlined';
  inputPlaceholder?: string;
  error?: boolean;
  helperText?: string;
  renderTags?: (value: T[], getTagProps: AutocompleteRenderGetTagProps) => ReactNode;
  renderOption?: (props: HTMLAttributes<HTMLLIElement>, option: T, state: AutocompleteRenderOptionState) => ReactNode;
  renderInputSx?: SxProps<Theme>;
  formHelperTextProps?: Partial<FormHelperTextProps>;
}

const Autocomplete: ElementType = <T extends unknown> ({
  field,
  autocompleteState,
  label,
  alternativeLabel,
  variant = 'outlined',
  inputPlaceholder = '',
  renderTags,
  renderInputSx,
  renderOption,
  error,
  helperText,
  formHelperTextProps,
  ...props
}: AutocompleteProps<T>) => {
  const [ value, setValue ] = useRecoilState<T | null>(field.valueState);
  const [ inputValue, setInputValue ] = useRecoilState(autocompleteState.inputState);
  const options = useRecoilValueLoadable(autocompleteState.optionsState);

  return (
    <MuiAutocomplete
      {...props}
      filterOptions={(options) => options}
      forcePopupIcon={!!props.inputValue}
      fullWidth
      inputValue={inputValue}
      loading={options.state === 'loading'}
      onChange={(_: unknown, value: T | null) => setValue(value)}
      onInputChange={(_: unknown, value: string) => setInputValue(value)}
      options={options.valueMaybe() ?? []}
      renderInput={
        (p) => (
          <TextField
            {...p}
            FormHelperTextProps={formHelperTextProps}
            error={error}
            helperText={helperText}
            // Use alternativeLabel if value is present, otherwise use label
            label={value ? alternativeLabel : label}
            placeholder={inputPlaceholder}
            sx={renderInputSx}
            variant={variant}
          />
        )
      }
      renderOption={renderOption}
      renderTags={renderTags}
      value={value}
    />
  );
};

export default Autocomplete;
