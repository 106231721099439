import { FunctionComponent } from 'react';
import { useSetRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import { Link as MuiLink, LinkProps } from '@mui/material';

import {
  ExternalLinkModalText,
  isRouteNonStride,
} from '../constants/externalLinkModalText';
import { openInNewTab } from '../lib/link';
import { isInternalRoute } from '../lib/routes';
import { externalLinkModalState } from '../state/atoms/externalLinkModalState';

type CustomLinkProps = LinkProps & {
  href?: string;  // Make href a required string
  externalLinkModalTextOverride?: ExternalLinkModalText; // use sparingly
  onClick?: () => void;
  dataCy?: string;
};

const CustomLink: FunctionComponent<CustomLinkProps> = ({
  dataCy = '',
  externalLinkModalTextOverride,
  ...props
}) => {
  // Const to indicate if route is external
  const [ isNonStrideLink, modalText ] = externalLinkModalTextOverride
    ? [ true, externalLinkModalTextOverride ]
    : isRouteNonStride(props.href);
  const setExternalLinkModalState = useSetRecoilState(externalLinkModalState);

  const router = useRouter();

  const removeQueryParams = (href:string) => {
    let pathname = href.split('?')[0];

    if (pathname.endsWith('/')) {
      pathname = pathname.slice(0, -1);
    }

    return pathname;
  };

  /**
   * default onClick function to preserve original behavior of the `CustomLink` component
   * If a customer function is passed, it will override this one
   */
  const defaultOnClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!props.href) return;
    e.preventDefault();

    if (isNonStrideLink) {
      // Href is external
      setExternalLinkModalState({
        isExternalLinkModalOpen: true,
        externalLinkModalText: modalText,
        onContinueUrl: props.href,
      });
    } else {
      if (isInternalRoute(removeQueryParams(props.href))) {
        // Internal (portal specific route), push using route
        router.push(props.href);
      } else {
        // For non-portal links (stride marketing) open in new tab
        openInNewTab(props.href);
      }
    }
  };

  return (
    <MuiLink
      {...props}
      data-cy={dataCy}
      href={props.onClick ? undefined : props.href}
      onClick={props.onClick ? props.onClick : defaultOnClick}
      sx={
        {
          ...props.sx,
          textDecoration: 'none', // Can be overridden with input props
        }
      }
    >
      {props.children}
    </MuiLink>
  );
};

export { CustomLink };
