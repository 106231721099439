import { CommonApplicationProfile, CommonApplicationProfileFinancingInfo } from '../../../types/commonApplicationProfile';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

// Using a mapped type to make all properties of the original interface non-nullable
type SubmitCommonApplicationProfileFinancingInfoParams = {
  [P in keyof CommonApplicationProfileFinancingInfo]: NonNullable<CommonApplicationProfileFinancingInfo[P]>;
};

const updateCommonApplicationProfileFinancingInfo = async (requestData: SubmitCommonApplicationProfileFinancingInfoParams): Promise<AxiosResponse<CommonApplicationProfile>> => {
  return await backendAuthenticatedAxios.patch<CommonApplicationProfile>('/common-application-profile/update-financing-info', requestData);
};

export default updateCommonApplicationProfileFinancingInfo;
