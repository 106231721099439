import { Disbursement } from '../../../../types/disbursement';
import { backendAuthenticatedAxios } from '../..';
import { getFiltersQueryParams, getOrderingQueryParams, getPaginationQueryParams } from '../../pagination';
import { PaginatedResponse } from '../../types';

import { AxiosResponse } from 'axios';
import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from 'material-react-table';

export type GetDisbursementsResponse = PaginatedResponse<Disbursement>;

const searchDisbursements = async (
  applicationIdList: string[],
  pagination: MRT_PaginationState,
  filters?: MRT_ColumnFiltersState,
  sorting?: MRT_SortingState,
): Promise<AxiosResponse<GetDisbursementsResponse>> => {
  // Relative URL
  const relativeUrl = '/staff/disbursement/search';

  // Query parameters
  const paginationOptions = getPaginationQueryParams(pagination);
  const orderingOptions = sorting ? getOrderingQueryParams(sorting) : {};
  const filterOptions = filters ? getFiltersQueryParams(filters) : {};

  const queryParams = new URLSearchParams({
    ...paginationOptions,
    ...orderingOptions,
    ...filterOptions,
    'originator_app_id_in':  applicationIdList.join(','),
  });

  // Encode Partner name to account for spaces and reduce transformations across interfaces
  return backendAuthenticatedAxios.get<GetDisbursementsResponse>(`${relativeUrl}?${queryParams.toString()}`);
};

export default searchDisbursements;
