import { Interview, SponsorshipApplication } from '../../../types/sponsorshipApplication';
import { SponsorshipApplicationStatus, SponsorshipApplicationSubstatus } from '../../../types/sponsorshipApplicationStatuses';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

export interface StatusTransition {
  status: SponsorshipApplicationStatus | SponsorshipApplicationSubstatus;
  interview?: Partial<Omit<Interview, 'id'>>;
  estimated_employment_start_date?: string;
}

const updateSponsorshipApplicationStatus = async (
  sponsorshipAppId: string,
  statusTransitionParams: StatusTransition,
): Promise<AxiosResponse<SponsorshipApplication | null>> => {
  let requestData: StatusTransition = { 'status': statusTransitionParams.status };

  if (statusTransitionParams.interview) {
    requestData = { ...requestData, 'interview': statusTransitionParams.interview };
  }

  if (statusTransitionParams.estimated_employment_start_date) {
    requestData = { ...requestData, 'estimated_employment_start_date': statusTransitionParams.estimated_employment_start_date };
  }

  return backendAuthenticatedAxios.post<SponsorshipApplication>(
    `/sponsorship-applications/${sponsorshipAppId}/update-status`, requestData,
  );
};

export default updateSponsorshipApplicationStatus;
