import { FunctionComponent } from 'react';
import Image, { ImageProps } from 'next/image';

import { altLogoImg } from '../../constants/branding';
import { CustomLink } from '../CustomLink';

import brandLogo from '../../public/logo/clasp.svg';

export interface LogoProps extends Omit<ImageProps, 'src'> {
    href?: string;
    size: 'xs' | 'sm' | 'md' | 'xl';
}

const logoMapProps = {
  xs: { width: 77, height: 77 },
  sm: { width: 88, height: 88 },
  md: { width: 120, height: 120 },
  xl: { width: 148, height: 148 },
};

const Logo: FunctionComponent<LogoProps> = ({
  href,
  size,
  ...props
}) => {
  const { alt, ...restProps } = props;

  const logoImageDimensions = logoMapProps[size] || logoMapProps.md;

  return (
    href
      ? (
        <CustomLink href={href}>
          <Image alt={alt ?? altLogoImg} src={brandLogo} {...logoImageDimensions} {...restProps} />
        </CustomLink>
      )
      : (
        <Image alt={alt ?? altLogoImg} src={brandLogo} {...logoImageDimensions} {...restProps} />
      )
  );
};

export default Logo;
