import { StudentSponsorshipOpportunity } from '../../../types/sponsorshipOpportunity';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const getEligibleOpportunities = async (): Promise<AxiosResponse<StudentSponsorshipOpportunity[]>> => {
  return backendAuthenticatedAxios.get<StudentSponsorshipOpportunity[]>('/esl-student/get-opportunity-matches');
};

export default getEligibleOpportunities;
