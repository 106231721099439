import {
  atom,
  RecoilState,
  RecoilValueReadOnly,
  selector,
} from 'recoil';

import { asyncDebounce } from '../../debounce';

const DEFAULT_DEBOUNCE_TIMEOUT_MS = 300;

/**
 * Manages state associated with Autocomplete components that fetch options as the user types
 *
 * Designed to be compatible with [Material UI's Autocomplete component
 *  ](https://mui.com/api/autocomplete/)
 */
class ServerSideAutocomplete<T> {
  /**
   * Value of the input field
   */
  inputState: RecoilState<string>;

  /**
   * Options returned by passing `inputState` to `fetch`
   *
   * @note this is automatically memoized
   * @readonly
   */
  optionsState: RecoilValueReadOnly<T[]>;

  /**
   * Create a new ServerSideAutocomplete instance
   *
   * @param key used to prefix keys of Recoil pieces state
   * @param fetch callback that fetches the options
   * @param debounce Whether to use a debounce function when fetching
   * @param debounceTimeout Amount of time to wait in between debounced API calls
   * @param inputState (optional) value of the input field
   *
   * @example
   *  const dogBreedAutocomplete =
   *    new ServerSideAutocomplete<DogBreed>(
   *      'dogBreed',
   *      async (query) => {...},
   *      false,
   *      200,
   *      dogBreedInput,
   *    );
   */
  constructor(
    key: string,
    fetch: (query: string) => Promise<T[]>,
    debounce?: boolean,
    debounceTimeout: number = DEFAULT_DEBOUNCE_TIMEOUT_MS,
    inputState = atom<string>({
      key: `${key}/Autocomplete/input`,
      default: '',
    }),
  ) {
    this.inputState = inputState;

    // Create optionsState selector
    this.optionsState = selector<T[]>({
      key: `${key}/Autocomplete/options`,
      get: async ({ get }) => {
        if (debounce) {
          const debouncedFetch = asyncDebounce(() => fetch(get(this.inputState)), debounceTimeout);

          return debouncedFetch();
        }

        return fetch(get(this.inputState));
      },
    });
  }
}

export default ServerSideAutocomplete;
